import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introdução",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introdu%C3%A7%C3%A3o",
        "aria-label": "introdução permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introdução`}</h1>
    <p><img parentName="p" {...{
        "src": "/images/cover.png",
        "alt": "Capa"
      }}></img></p>
    <p>{`Nesse guia iremos mostrar o passo a passo para preparar o seu Ambiente React Native em sistemas Windows, Linux e macOS. Você irá aprender também como executar suas aplicações React Native em emuladores Android/iOS e em dispositivos físicos Android/iOS (via USB e Wi-Fi). Além disso, falaremos também sobre os erros mais comuns relacionados a React Native e como solucioná-los.`}</p>
    <p>{`Para configurar o seu sistema para executar aplicações React Native iOS e Android utilizando Expo Managed Workflow, escolha uma das opções abaixo:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/expo-managed/windows"
        }}>{`Windows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/expo-managed/linux"
        }}>{`Linux`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/expo-managed/macos"
        }}>{`macOS`}</a></li>
    </ul>
    <p>{`Para configurar o seu sistema para executar aplicações React Native Android utilizando Expo Bare Workflow ou React Native CLI, escolha uma das opções abaixo:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/android/windows"
        }}>{`Windows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/android/linux"
        }}>{`Linux`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/android/macos"
        }}>{`macOS`}</a></li>
    </ul>
    <p>{`Para configurar o seu sistema para executar aplicações React Native iOS utilizando Expo Bare Workflow ou React Native CLI, clique no link abaixo:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Infelizmente no Windows e no Linux ainda não é possível configurar um ambiente iOS de forma nativa e, por isso, vamos focar no Android nessas duas plataformas.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ios/macos"
        }}>{`macOS`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      